











































































































































































































































































































































































































































































































































































































































































































.offer-letter {
  max-width: 100%;
  min-height: 500px;
  width: 768px;
  @media screen {
    @apply bg-white mx-auto rounded-b pt-10 px-10 pb-20 {}
    @apply border border-blue-200 {}
    border-top: 5px solid theme('colors.blue.500');
    box-shadow: 0 10px 20px rgba(#000, .1); }
  ol,
  ul {
    li {
      @apply mb-5 ml-4 {}
      &.mb-0 {
        @apply mb-0 {} } } }
  ol {
    list-style: decimal;
    &.lower-alpha {
      list-style: lower-alpha; } }
  ul {
    list-style: disc; }
  .placeholder {
    @apply inline-block {}
    width: 350px; }
  .actions {
    @media print {
      @apply hidden {} } } }
